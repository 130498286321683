import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Link from "next/link";
import Image from "next/image";

import carouselBGimg2 from "../../assets/images/home/Banner/UPSC-CSE-2024-Webbanner.jpg";
import carouselBGimg3 from "../../assets/images/home/Banner/UPSC-Result-2023-Web-Banner.webp";
import carouselBGimg4 from "../../assets/images/home/Banner/TNPSC-GR-I-II-Prelims-webbanner-Clickhere.jpg";
import carouselBGimg5 from "../../assets/images/home/Banner/UPSC-Result-2023-National Topper-Webpanner.webp";
import carouselBGimg6 from "../../assets/images/home/Banner/Moksha-2024-Web-Banner-clickhere.jpg";
import carouselBGimg7 from "../../assets/images/home/Banner/Forestry-Result-2023-Web-Banner.jpg";
import carouselBGimg8 from "../../assets/images/home/Banner/Sadhana-webbanner-2026.jpg";
import carouselBGimg9 from "../../assets/images/home/Banner/TNPSC-New-location-Web-banner.jpg";
import carouselBGimg11 from "../../assets/images/home/Banner/Congralutations-banner.jpg";
import carouselBGimg12 from "../../assets/images/home/Banner/TNPSC-GR-I-Mains-Test-Series_Web-Banner.jpg";
import carouselBGimg13 from "../../assets/images/home/Banner/Forestry/Forestry-Optional-Web-Banner.jpg";
import carouselBGimg16 from "../../assets/images/upsc/G1-Result/TNPSC-GR-I-Result-2022_Webbanner.jpg";
import carouselBGimg17 from "../../assets/images/home/Banner/SI-Test-Series-Webbanner.jpg";
import carouselBGimg18 from "../../assets/images/home/Banner/TNPSC-Gr1-Web-Banner-Click-Here.jpg";
import carouselBGimg19 from "../../assets/images/home/Banner/prestorming-web-banner-with-button.jpg";
import carouselBGimg20 from "../../assets/images/home/Banner/All-India-Mocktest-Web-banner.jpg";

    
{/* mobile */}
import carouselMBimg2 from "../../assets/images/home/Mob_Banner/UPSC-CSE-2024-Mobbanner.jpg";
import carouselMBimg3 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-Moblie-Banner.webp";
import carouselMBimg4 from "../../assets/images/home/Mob_Banner/TNPSC-GR-I-&-II-Prelims-Mobile-banner-Clickhere.jpg";
import carouselMBimg5 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-National-toppers-Mobilepaner.webp";
import carouselMBimg6 from "../../assets/images/home/Mob_Banner/Foresty-Result-2023-Moblie-Banner.jpg";
import carouselMBimg7 from "../../assets/images/home/Mob_Banner/Sadhana-Mobile-Banner-2026.jpg";
import carouselMBimg8 from "../../assets/images/home/Mob_Banner/Congralutations-banner.jpg";
import carouselMBimg9 from "../../assets/images/home/Mob_Banner/TNPSC-New-location-Mobile-banner.jpg";
import carouselMBimg11 from "../../assets/images/home/Mob_Banner/Moksha-2024-Mobile-Banner-clickhere.jpg";
import carouselMBimg12 from "../../assets/images/home/Mob_Banner/TNPSC-GRI-Mains-Test-Series-Mobille-Banner.jpg";
import carouselMBimg13 from "../../assets/images/home/Banner/Forestry/Forestry-Optional-Mobile-banner.jpg";
import carouselMBimg16 from "../../assets/images/upsc/G1-Result/TNPSC-GR-I-Result-2022-Mobilebanner.jpg";
import carouselMBimg17 from "../../assets/images/home/Mob_Banner/SI-Test-Series-Mobilebanner.jpg";
import carouselMBimg18 from "../../assets/images/home/Mob_Banner/TNPSC-Gr1-Mobile-Banner-Click-Here.jpg";
import carouselMBimg19 from "../../assets/images/home/Mob_Banner/prestorming-Mob-banner-with-button.jpg";
import carouselMBimg20 from "../../assets/images/home/Mob_Banner/All-India-Mocktest-Mobile-banner.jpg";


function slider() {
    const BGimg = [
        { quickLoading: false, img: carouselBGimg11, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg3, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg13, alt: "carousel", link: "/forestry-admission" },
        { quickLoading: false, img: carouselBGimg20, alt: "carousel", link: "/upsc-all-india-mains-mock-test" },
        { quickLoading: false, img: carouselBGimg19, alt: "carousel", link: "/upsc-prestorming" },
        { quickLoading: false, img: carouselBGimg18, alt: "carousel", link: "/tnpsc-group-i-mains-crash-course" },
        { quickLoading: false, img: carouselBGimg6, alt: "carousel", link: "/upsc-mains-test-series" },
        { quickLoading: false, img: carouselBGimg12, alt: "carousel", link: "/tnpsc-group-i-mains-test-series" },
        { quickLoading: false, img: carouselBGimg7, alt: "carousel", link: "/upsc-admission-anna-nagar" },        
        { quickLoading: false, img: carouselBGimg17, alt: "carousel", link: "/si-test-series" },
        { quickLoading: false, img: carouselBGimg8, alt: "carousel", link: "/two-year-upsc-program" },
        { quickLoading: false, img: carouselBGimg16, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselBGimg4, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselBGimg9, alt: "carousel", link: "/tnpsc-admissions" },
       
      ]
      const MBimg = [
        { quickLoading: false, img: carouselMBimg8, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg3, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg13, alt: "carousel", link: "/forestry-admission" },
        { quickLoading: false, img: carouselMBimg20, alt: "carousel", link: "/upsc-all-india-mains-mock-test" },
        { quickLoading: false, img: carouselMBimg19, alt: "carousel", link: "/upsc-prestorming" },
        { quickLoading: false, img: carouselMBimg18, alt: "carousel", link: "/tnpsc-group-i-mains-crash-course" },
        { quickLoading: false, img: carouselMBimg6, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg7, alt: "carousel", link: "/two-year-upsc-program" },
        { quickLoading: false, img: carouselMBimg12, alt: "carousel", link: "/tnpsc-group-i-mains-test-series" },
        { quickLoading: false, img: carouselMBimg17, alt: "carousel", link: "/si-test-series" },
        { quickLoading: false, img: carouselMBimg16, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselMBimg4, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselMBimg9, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselMBimg11, alt: "carousel", link: "/upsc-mains-test-series" },
       // { quickLoading: false, img: carouselMBimg15, alt: "carousel", link: "/delhi-upsc-current-affairs" },
        
      ]
    


  return (
    <>
        {/* Carousel large devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-md-block">
            <Carousel>
              {BGimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid w-100 h-100"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

      {/* Carousel Mobile devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-block d-md-none">
            <Carousel>
              {MBimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

    </>
  )
}

export default slider